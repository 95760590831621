.footer {
    background-color: #f1f1f1;
    text-align: left;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .footer p {
    margin: 5px 0;
    color: #333;
  }

  .footer a {
    margin-right: 20px;
  }